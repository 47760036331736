import React from "react"
import Layout from "../components/layout"
import CharityTabs from "../components/CharityTabs.js"
import { Container, Row, Col } from "react-bootstrap"
import "react-id-swiper/lib/styles/css/swiper.css"
import { LazyLoadComponent } from "react-lazy-load-image-component"


const Charity = ({ data }) => {
  return (
    <Layout>
      <div className="about-us-page">
        <div>
          <CharityTabs />
        </div>
      </div>
    </Layout>
  )
}

export default Charity
